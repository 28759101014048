<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：点位管理 - 新增
    功能概述：新增
-->
<style lang="less">
.location-add-submit-content {
  .tips {
    margin-top: 10px;
    color:#9ea7b4;
    font-size: 12px;
    line-height: 20px;
    a {
      color: @themeColor;
    }
  }
  .coordinate-sample-graph {
    width: 100%;
    height: auto;
  }
}

</style>

<template>
  <yg-modal
    width="600"
    title="新增点位"
    sure-text="保存"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 200px"
    >
      <div class="location-add-submit-content">
        <Form>
          <FormItem
            v-for="(item, index) in formItems"
            :key="index"
            :label="item.label"
            :label-width="item.labelWidth"
            :required="item.isRequired"
          >
            <yg-site-cascader
              v-if="item.tagName === 'YgSiteCascader'"
              v-model="model[item.key]"
              :level="item.data"
              :disabled="item.disabled"
              style="width: 100%"
              @input="_selectedBlock"
            />
            <Select
              v-if="item.tagName === 'Select'"
              v-model="model[item.key]"
              :clearable="item.clearable"
              :disabled="item.disabled"
              style="width: 100%"
              @input="checkSubmit"
            >
              <Option
                v-for="(op, p) in item.data"
                :key="p + '-op'"
                :value="op.value"
              >
                {{ op.label }}
              </Option>
            </Select>
            <Input
              v-if="item.tagName === 'Input'"
              v-model="model[item.key]"
              type="text"
              :maxlength="item.maxLength"
              :placeholder="'请输入' + item.label"
              :disabled="item.disabled"
              @input="checkSubmit"
            />
            <div
              v-if="item.tagName === 'Div'"
            >
              {{ model[item.key] || '-' }}
            </div>
            <!--经纬度查询提示-->
            <div
              v-if="item.key === 'lat'"
              class="tips"
            >
              <span>如何查询门店地址坐标？</span>
              <a
                target="_blank"
                href="https://lbs.qq.com/getPoint/"
              >获取门店坐标 > </a>
              <a @click="sampleGraphModal = true">查看示例图</a>
            </div>
          </FormItem>
        </Form>
        <!--坐标示例图弹窗-->
        <yg-modal
          v-if="sampleGraphModal"
          width="90%"
          title="获取门店坐标示例图"
          :show-bottom="false"
          @on-close="sampleGraphModal = false"
        >
          <div
            slot="content"
            style="min-height: 200px"
          >
            <img
              class="coordinate-sample-graph"
              src="../../../../assets/image/coordinate-sample-graph.png"
            >
          </div>
        </yg-modal>
      </div>
    </div>
  </yg-modal>
</template>

<script>

import CommunityApiSet from '@/request/api/CommunityApiSet';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      formItems: [
        {
          label: '所属服务商',
          labelWidth: 135,
          key: 'providerName',
          tagName: 'Div',
          isRequired: false
        },
        {
          label: '所属物业/小区',
          labelWidth: 135,
          key: 'communityData',
          tagName: 'YgSiteCascader',
          disabled: false,
          clearable: true,
          isRequired: true,
          data: 2
        },
        {
          label: '所在省市区',
          labelWidth: 135,
          key: 'h5Area',
          tagName: 'Div',
          isRequired: false
        },
        {
          label: '点位名称',
          labelWidth: 135,
          key: 'name',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '40'
        },
        {
          label: '详细地址',
          labelWidth: 135,
          key: 'address',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '40'
        },
        {
          label: '经度',
          labelWidth: 135,
          key: 'lng',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '60'
        },
        {
          label: '纬度',
          labelWidth: 135,
          key: 'lat',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '60'
        }
      ],
      // 示例图弹窗
      sampleGraphModal: false
    };
  },
  created () {
    this.model.providerName = this.$storage.merchantInfo.providerName || this.$storage.merchantInfo.serviceProviderName;
  },
  methods: {
    onSure () {
      const res = this.model.checkValidAddition();
      console.log(res);
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.$emit('on-sure', res.params);
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    },
    // 选择小区之后，查询小区所在的省市区
    _selectedBlock () {
      this.model.h5Area = '';
      if (this.model.communityData.length === 4) {
        const api = CommunityApiSet.communityInfo;
        api.params = {
          id: this.model.communityData[3] || ''
        };
        this.$http(api).then(res => {
          this.model.h5Area = `${res.data.provinceName || ''}${res.data.cityName || ''}${res.data.districtName || ''}`;
        });
      }
    }
  }
};
</script>
