<!--
    Created by 程雨喵'mac on 2024/1/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：设置-联系人设置
-->
<style lang="less">
</style>

<template>
  <Form>
    <FormItem
      v-for="(item, index) in formItems"
      :key="index"
      :label="item.label"
      :label-width="item.labelWidth"
      :required="item.isRequired"
    >
      <Input
        v-if="item.tagName === 'Input'"
        v-model="model[item.key]"
        type="text"
        :maxlength="item.maxLength"
        :placeholder="'请输入' + item.label"
        :disabled="item.disabled"
        @input="checkSubmit"
      />
    </FormItem>
  </Form>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      formItems: [
        {
          label: '运维服务联系人',
          labelWidth: 130,
          key: 'manager',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '20'
        },
        {
          label: '运维服务电话',
          labelWidth: 130,
          key: 'serviceCall',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '11'
        }
      ]
    };
  },
  created () {
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    }
  }
};
</script>
