<!--
    Created by 王丽莎 on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：点位管理-点位列表
-->
<style lang="less">
</style>

<template>
  <section>
    <!-- 查询条件 -->
    <div
      v-permission="'system:site:query'"
    >
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:site:query'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：新增-->
    <v-add
      v-if="!!dialog.addModel"
      :model="dialog.addModel"
      @on-close="dialog.addModel = null"
      @on-sure="_clickAddSubmit"
    />
    <!--弹窗：编辑-->
    <v-submit
      v-if="!!dialog.submitModel"
      :index="dialog.settingIndex"
      :model="dialog.submitModel"
      @on-close="_clickEditClose"
    />
    <!--弹窗：查看各类型订单-->
    <yg-order-list
      v-if="!!dialog.orderListModel"
      :parameter-type="dialog.parameterType"
      :type="dialog.orderListType"
      :model="dialog.orderListModel"
      @on-close="dialog.orderListModel = null"
      @on-sure="dialog.orderListModel = null"
    />
  </section>
</template>

<script>
import LocationApiSet from '@/request/api/LocationApiSet';
import { LocationListModel } from './model/LocationDataModel';
import ColumnMixin from '@/pages/040__locationManager/locationList/mixin/tableColumn';
import vAdd from './view/add.vue';
import vSubmit from './view/submit/index.vue';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';

export default {
  components: { vAdd, vSubmit },
  mixins: [ColumnMixin, commonPageDrawerMixin],
  data () {
    return {
      searchOption: {
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        siteLabel: BaseSearchModel.initData('点位名称点位名', 'siteLabel', 'Input').setLabelSelect([
          { value: 'siteName', label: '点位名称' },
          { value: 'siteCode', label: '点位编码' }
        ]),
        time: BaseSearchModel.initData('创建时间', 'time', 'DatePickerRange').setDatePickerElevator([])
      },
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:site:add')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      // 新增/编辑组件表单类型 true：详情 false：运维人员
      isInfo: true,
      dialog: {
        addModel: null,
        submitModel: null,
        settingIndex: 0,
        orderListModel: null,
        orderListType: null,
        parameterType: null
      }
    };
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    // 点击查询
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    // 点击设置
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.addModel = LocationListModel.createAdditionModel();
        break;
      }
    },
    // 提交新增
    _clickAddSubmit (params) {
      this.requestAdd(params);
    },
    // 关闭编辑
    _clickEditClose (needRefresh) {
      if (needRefresh) {
        this.onChangePage(1);
      }
      this.dialog.submitModel = null;
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 获取列表
    requestData () {
      const s = this.searchOption;
      const api = LocationApiSet.getSiteListApi;
      api.params = {
        province: s.region.value[0] || '',
        city: s.region.value[1] || '',
        district: s.region.value[2] || '',
        communityName: s.communityName.value,
        startTime: s.time.value[0] || '',
        endTime: s.time.value[1] || '',
        pageNo: this.page.current,
        pageSize: this.$config.pageSize
      };
      this.tableData = [];
      this.page.total = 0;
      // 筛选中可下拉的label
      api.params[s.siteLabel.labelSelectValue] = s.siteLabel.value;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = LocationListModel.initListWithDataList(resData.rows);
      });
    },
    // 切换状态
    requestChangeStatus (row, status) {
      const api = LocationApiSet.changeStatusApi;
      api.params = {
        id: row.id,
        status: status ? 1 : 0
      };
      this.$http(api).then(res => {
        console.log(res);
        this.$Message.success('操作成功');
        this.requestData();
      })
        .catch(e => {
          row.status = status ? 0 : 1;
          row.h5StatusSwitch = !status;
        });
    },
    // 新增
    requestAdd (params) {
      const api = LocationApiSet.addSiteApi;
      api.params = params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.dialog.addModel = null;
        this.requestData();
      });
    },
    // 获取详情
    requestInfoData (id) {
      const api = LocationApiSet.getSiteInfoApi;
      api.params = {
        id: id
      };
      this.$http(api).then(res => {
        if (res.data.id) {
          this.dialog.submitModel = LocationListModel.initModelWithData(res.data);
        } else {
          this.$Message.warning('缺少重要参数');
        }
      });
    },
    // 获取设备数量
    requestDeviceCount (row) {
      const api = LocationApiSet.getDeviceCountApi;
      api.params = {
        id: row.id
      };
      this.$http(api).then(res => {
        console.log(res);
        if (res.data.deviceCount > 0) {
          if (row.propertyCompanyId && row.province && row.city && row.communityId && row.id) {
            row.siteIdArr = [row.propertyCompanyId, row.province, row.city, row.communityId, row.id];
            this.dialog.parameterType = 4;
            this.dialog.orderListType = 5;
            this.dialog.orderListModel = row;
          } else {
            this.$Message.warning('缺少重要参数');
          }
        } else {
          this.$Message.warning('当前点位无设备，请联系工作人员安装');
        }
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
