<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：点位管理 - 编辑详情/运维人员
    功能概述：编辑详情/运维人员
-->
<style lang="less">
.location-edit-info-content {
  .tips {
    margin-top: 10px;
    color:#9ea7b4;
    font-size: 12px;
    line-height: 20px;
    a {
      color: @themeColor;
    }
  }
  .coordinate-sample-graph {
    width: 100%;
    height: auto;
  }
}

</style>

<template>
  <div class="location-edit-info-content">
    <Form>
      <FormItem
        v-for="(item, index) in formItems"
        :key="index"
        :label="item.label"
        :label-width="item.labelWidth"
        :required="item.isRequired"
      >
        <Input
          v-if="item.tagName === 'Input'"
          v-model="model[item.key]"
          type="text"
          :maxlength="item.maxLength"
          :placeholder="'请输入' + item.label"
          :disabled="item.disabled"
          @input="checkSubmit"
        />
        <div v-if="item.tagName === 'Div'">
          {{ model[item.key] || '-' }}
        </div>
        <div
          v-if="item.key === 'lat'"
          class="tips"
        >
          <span>如何查询门店地址坐标？</span>
          <a
            target="_blank"
            href="https://lbs.qq.com/getPoint/"
          >获取门店坐标 > </a>
          <a @click="sampleGraphModal = true">查看示例图</a>
        </div>
      </FormItem>
    </Form>
    <!--坐标示例图弹窗-->
    <yg-modal
      v-if="sampleGraphModal"
      width="90%"
      title="获取门店坐标示例图"
      :show-bottom="false"
      @on-close="sampleGraphModal = false"
    >
      <div
        slot="content"
        style="min-height: 200px"
      >
        <img
          class="coordinate-sample-graph"
          src="../../../../../assets/image/coordinate-sample-graph.png"
        >
      </div>
    </yg-modal>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      formItems: [
        {
          label: '所属服务商',
          labelWidth: 120,
          key: 'providerName',
          tagName: 'Div',
          isRequired: false
        },
        {
          label: '所属物业公司',
          labelWidth: 120,
          key: 'propertyCompanyName',
          tagName: 'Div',
          isRequired: false
        },
        {
          label: '所属小区',
          labelWidth: 120,
          key: 'communityName',
          tagName: 'Div',
          isRequired: false
        },
        {
          label: '所在省市区',
          labelWidth: 120,
          key: 'h5Area',
          tagName: 'Div',
          isRequired: false
        },
        {
          label: '点位名称',
          labelWidth: 120,
          key: 'name',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '40'
        },
        {
          label: '详细地址',
          labelWidth: 120,
          key: 'address',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '40'
        },
        {
          label: '经度',
          labelWidth: 120,
          key: 'lng',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '60'
        },
        {
          label: '纬度',
          labelWidth: 120,
          key: 'lat',
          tagName: 'Input',
          disabled: false,
          isRequired: true,
          maxLength: '60'
        }
      ],
      // 示例图弹窗
      sampleGraphModal: false
    };
  },
  created () {
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    onSure () {
      this.$emit('on-sure');
    },
    onClose () {
      this.$emit('on-close');
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    }
  }
};
</script>
